<template>
  <b-card
    no-body
    class="mb-0"
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Actions -->
        <b-col
          cols="12"
          md="8"
        >
          <slot
            name="pre_bulk_actions"
            :isSortingActive="isSortingActive"
            :isSomeRowsSelected="isSomeRowsSelected"
            :isAllRowsSelected="isAllRowsSelected"
            :rowsSelected="rowsSelected"
          />
          <template v-if="addAction && !isSortingActive">
            <template v-if="addAction==='sidebar'">
              <b-button
                v-b-toggle.add-new-sidebar
                variant="primary"
                class="mr-1"
                @click="itemInFocusId=null"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span
                  v-t="{path:'actions.add', args: {'model': $t(`modules.${storeModuleName}.s_s`)}}"
                  class="align-middle text-nowrap"
                />
              </b-button>
            </template>
            <template v-else>
              <b-button
                variant="primary"
                class="mr-1"
                :to="{ name: `${storeModuleName}.add` }"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span
                  v-t="{path:'actions.add', args: {'model': $t(`modules.${storeModuleName}.s_s`)}}"
                  class="align-middle text-nowrap"
                />
              </b-button>
            </template>
          </template>
          <b-button
            v-if="deleteAction && bulkDeleteAction && !isSortingActive"
            id="delete-records-btn"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="danger"
            class="mr-1"
            :disabled="!isSomeRowsSelected && !isAllRowsSelected"
            @click="deleteItems"
          >
            <feather-icon
              :icon="deleteIcon"
              class="mr-50"
            />
            <span
              v-t="deleteLabel"
              class="align-middle text-nowrap"
            />
          </b-button>
          <template v-if="isSortable">
            <b-button
              v-if="!isSortingActive"
              id="trigger-sorting-btn"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="warning"
              class="mr-1"
              @click="startSorting"
            >
              <material-icon
                icon="low_priority"
                class="mr-50"
                size="16"
              />
              <span
                v-t="`Change Display Order`"
                class="align-middle text-nowrap"
              />
            </b-button>
            <b-button
              v-if="isSortingActive"
              id="save-sorting-btn"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="success"
              class="mr-1"
              :disabled="!hasReorderedRows || isTableBusy"
              @click="saveRowsSorting(totalItems, perPage, currentPage)"
            >
              <material-icon
                icon="save"
                class="mr-50"
                size="16"
              />
              <span
                v-t="`Save Order`"
                class="align-middle text-nowrap"
              />
            </b-button>
            <b-button
              v-if="isSortingActive"
              id="cancel-sorting-btn"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
              class="mr-1"
              :disabled="isTableBusy"
              @click="isSortingActive=false"
            >
              <material-icon
                icon="close"
                class="mr-50"
                size="16"
              />
              <span
                v-t="`Cancel Changes`"
                class="align-middle text-nowrap"
              />
            </b-button>
          </template>
          <slot
            name="extra-container-actions"
            v-bind="{ isSortingActive, isTableBusy }"
          />
          <b-button
            v-if="!isSortingActive"
            id="refresh-btn"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="mr-1"
            @click="refreshData"
          >
            <feather-icon
              icon="RefreshCwIcon"
            />
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="4"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-if="isSearchable"
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="`${ $t('Search') }...`"
            />
            <slot name="filters" />
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refListTable"
      v-draggabletable="{animation: 200, handle: '.draggable-handle'}"
      class="position-relative"
      :items="fetchItems"
      selectable
      select-mode="multi"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t(`No matching records found`)"
      :empty-filtered-text="$t(`No matching records found`)"
      :sort-desc.sync="isSortDirDesc"
      :busy.sync="isTableBusy"
      hover
      foot-clone
      striped
      :bordered="false"
      :tbody-tr-class="row => (row && row.id === rowUnderHover ? 'ready-for-drag' : '')"
      @row-selected="onRowSelected"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle m-50" />
          <strong>{{ $t('Loading') }}...</strong>
        </div>
      </template>

      <!-- Column: Draggable -->
      <template
        v-if="isSortingActive"
        #cell(draggable)="row"
      >
        <material-icon
          icon="drag_indicator"
          color="#a0a0a0"
          @mouseover="rowUnderHover = row.item.id"
          @mouseout="rowUnderHover = null"
        />
      </template>

      <!-- Header-Column: ALL -->
      <template #head()="slotData">
        <div
          v-if="typeof(slotData.label)=='string' && slotData.label"
          v-t="slotData.label"
        />
        <div v-else>
          {{ slotData.label }}
        </div>
      </template>
      <!-- Header-Column: Checkbox -->
      <template #head(checkbox)="slotData">
        <b-form-checkbox
          v-model="isAllRowsSelected"
          :indeterminate.sync="isSomeRowsSelected"
          @change="syncRowsSelection($event, slotData)"
        />
      </template>
      <!-- Column: Checkbox -->
      <template #cell(checkbox)="row">
        <b-form-checkbox
          v-model="row.rowSelected"
          @change="syncRowSelection($event,row)"
        />
      </template>
      <!-- Footer-Column: Checkbox -->
      <template #foot(checkbox)="" />

      <!-- Column: ID -->
      <template #cell(id)="data">
        <b-link
          v-if="viewAction"
          :to="{ name: `${storeModuleName}.view`, params: { id: data.item.id } }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.id }}
        </b-link>
        <div v-else>
          {{ data.item.id }}
        </div>
      </template>

      <!-- Column: Cart Subtotal -->
      <template #cell(cart_subtotal)="data">
        <div class="font-weight-bold d-block text-nowrap">
          {{ priceText(data.item.cart_subtotal) }}
        </div>
        <small class="text-muted">{{ $t(data.item.items_count > 1 ? 'modules.abandoned_carts._messages.x_products' : 'modules.abandoned_carts._messages.one_product', data.item.items_count, {x: data.item.items_count}) }}</small>
      </template>

      <!-- Column: User -->
      <template #cell(user)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.fullName)"
              :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
            />
          </template>
          <b-link
            :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.fullName }}
          </b-link>
          <small class="text-muted">@{{ data.item.username }}</small>
        </b-media>
      </template>

      <!-- Column: Customer -->
      <template #cell(customer)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :text="avatarText(data.item.name || `${data.item.first_name} ${data.item.last_name}`)"
              variant="light-primary"
              :to="{ name: 'customers.view', params: { id: data.item.id } }"
            />
          </template>
          <b-link
            :to="{ name: 'customers.view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name || `${data.item.first_name} ${data.item.last_name}` }}
          </b-link>
          <small class="text-muted">{{ data.item.email }}</small>
        </b-media>
      </template>
      <template #cell(related_customer)="data">
        <b-media
          v-if="data.item.customer"
          vertical-align="center"
        >
          <template #aside>
            <b-avatar
              size="32"
              :text="avatarText(data.item.customer.name)"
              :variant="data.item.customer.id ? `light-primary` : `light-secondary`"
              :to="data.item.customer.id ? { name: 'customers.view', params: { id: data.item.customer.id } } : null"
            />
          </template>
          <component
            :is="data.item.customer.id ? 'b-link' : 'span'"
            :to="data.item.customer.id ? { name: 'customers.view', params: { id: data.item.customer.id } } : null"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.customer.name }}
          </component>
          <small class="text-muted">{{ data.item.customer.email }}</small>
        </b-media>
        <p v-else>
          -
        </p>
      </template>

      <!-- Column: Affiliate -->
      <template #cell(affiliate)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :text="avatarText(data.item.name)"
              variant="light-primary"
              :to="{ name: 'affiliates.view', params: { id: data.item.id } }"
            />
          </template>
          <b-link
            :to="{ name: 'affiliates.view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
          <small class="text-muted">{{ data.item.email || (data.item.mobile ? data.item.formatted_mobile : '') }}</small>
        </b-media>
      </template>

      <!-- Column: Coupon Code -->
      <template #cell(coupon_code)="data">
        <div
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.code }}
        </div>
        <small class="text-muted">{{ data.item.name }}</small>
      </template>

      <!-- Column: Discount Value -->
      <template #cell(discount_value)="data">
        <div
          v-if="data.item.discount_type === 'fixed_discount'"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.discount_value / 100 }} <small>{{ getLocaleCurrency() }}</small>
        </div>
        <div
          v-else
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.discount_value }} <small>%</small>
        </div>
        <small class="text-muted">{{ $t('modules.coupons.inputs.discount_segment_options.' + data.item.discount_segment) }}</small>
      </template>

      <!-- Column: Discount Usability -->
      <template #cell(discount_usability)="data">
        <div
          class="font-weight-bold d-block text-nowrap"
        >
          {{ $t('modules.coupons.inputs.discount_usability_options.' + data.item.discount_usability) }}
        </div>
      </template>

      <!-- Column: Product -->
      <template #cell(product)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-img
              :src="storagePath(data.item.image, defaultProductImage)"
              rounded
              blank-color="#ccc"
              :placeholder="require('@/assets/images/avatars/2.png')"
              width="64"
              alt="Product Image"
              :to="{ name: `${storeModuleName}.edit`, params: { id: data.item.id } }"
            />
          </template>
          <b-link
            :to="{ name: `${storeModuleName}.edit`, params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
          <small class="text-muted">{{ data.item.category }}</small>
        </b-media>
      </template>

      <!-- Column: Role -->
      <template #cell(role)="data">
        <div class="text-nowrap">
          <feather-icon
            :icon="resolveUserRoleIcon(data.item.role)"
            size="18"
            class="mr-50"
            :class="`text-${resolveUserRoleVariant(data.item.role)}`"
          />
          <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ data.item.status }}
        </b-badge>
      </template>
      <template #cell(order_status)="data">
        <b-badge
          v-if="data.item.order_status"
          v-t="`modules.orders.status.${data.item.order_status}`"
          pill
          :variant="`light-${resolveOrderStatusVariant(data.item.order_status)}`"
          class="text-capitalize"
        >
          {{ data.item.status }}
        </b-badge>
        <b-badge
          v-else-if="data.item.status"
          v-t="`modules.orders.status.${data.item.status}`"
          pill
          :variant="`light-${resolveOrderStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ data.item.status_description || data.item.status }}
        </b-badge>
      </template>
      <template #cell(purchase_status)="data">
        <b-badge
          v-t="`modules.purchases.status.${data.item.status}`"
          pill
          :variant="`light-${resolvePurchaseStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ data.item.status_description || data.item.status }}
        </b-badge>
      </template>
      <template #cell(commission_status)="data">
        <b-badge
          v-if="data.item.commission_status"
          v-t="`modules.affiliates.commission_status.${data.item.commission_status}`"
          pill
          :variant="`light-${resolveCommissionStatusVariant(data.item.commission_status)}`"
          class="text-capitalize"
        >
          {{ data.item.status }}
        </b-badge>
      </template>
      <template #cell(notification_template_status)="data">
        <b-badge
          v-t="`modules.notification_templates.$stats.${data.item.status}`"
          pill
          :variant="`light-${resolveNotificationTemplateStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ data.item.status }}
        </b-badge>
      </template>
      <template #cell(order_short_id)="data">
        <b-link
          :to="{ name: 'orders.view', params: { id: data.item.order_id } }"
        >
          {{ data.item.order_short_id }}
        </b-link>
      </template>
      <template #cell(order_total)="data">
        <div class="font-weight-bold d-block text-nowrap">
          {{ priceText(data.item.total) }}
        </div>
        <small
          v-if="data.item.total_in_customer_currency"
          class="text-muted"
        >
          {{ priceText(data.item.total_in_customer_currency) }}
        </small>
      </template>
      <template #cell(paid_at)="data">
        <b-badge
          v-if="data.item.paid_at"
          pill
          :variant="`light-success`"
          class="text-capitalize"
        >
          {{ formatDatetime(data.item.paid_at) }}
        </b-badge>
        <b-badge
          v-else
          v-t="`modules.orders.payment_status.not_paid`"
          pill
          :variant="`light-secondary`"
          class="text-capitalize"
        />
      </template>

      <template #cell(is_active)="data">
        <b-badge
          v-t="data.item.is_active ? `Active` : `Inactive`"
          pill
          :variant="`light-${resolveBooleanStatusVariant(data.item.is_active)}`"
          class="text-capitalize"
        />
      </template>

      <!-- Column:NotificationStatus -->
      <template #cell(notification_status)="data">
        <b-avatar
          :id="`notification-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolveNotificationStatusVariantAndIcon(data.item.status).variant}`"
        >
          <feather-icon
            :icon="resolveNotificationStatusVariantAndIcon(data.item.status).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`notification-row-${data.item.id}`"
          placement="top"
        >
          <p class="mb-0">
            {{ $t(`modules.notification_campaigns._view.$stats.${data.item.status}`) }}
          </p>
          <p
            v-if="data.item.status==='scheduled'"
            class="mb-0"
          >
            {{ formatDatetime(data.item.scheduled_at) }}
          </p>
          <!--          <p class="mb-0">-->
          <!--            Due Date: {{ data.item.dueDate }}-->
          <!--          </p>-->
        </b-tooltip>
      </template>

      <!-- Column:NotificationChannel -->
      <template #cell(notification_channel)="data">
        <span
          v-if="data.item.channel_type.svg"
          class="mr-25"
          style="width: 16px; height: 16px;"
          v-html="data.item.channel_type.svg"
        />
        <feather-icon
          v-else
          :icon="data.item.channel_type.icon"
        />
        <span>
          {{ data.item.channel_type.label }}
        </span>
      </template>

      <!-- Column:NotificationsCount -->
      <template #cell(reminder_notifications_count)="data">
        <b-link
          v-if="data.item.reminder_notifications_count"
          :to="{ name: 'notification_campaigns.list', query: { entity_type: 'CART', entities: [data.item.id] } }"
        >
          {{ $t('modules.abandoned_carts.tableColumns.x_reminder_notifications', { count: data.item.reminder_notifications_count }) }}
        </b-link>
        <span v-else>-</span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <!-- Preview Action -->
          <b-button
            v-if="previewAction"
            :id="`preview-row-${data.item.id}-btn`"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="link"
            class="p-1 text-secondary"
            :href="data.item.url || '#'"
            target="_blank"
            :disabled="Object.hasOwn(data.item, 'is_active') && !data.item.is_active"
          >
            <feather-icon
              icon="EyeIcon"
              size="18"
            />
            <div
              v-t="`Preview`"
              class="pt-50"
            />
          </b-button>

          <!-- View Action -->
          <b-button
            v-if="viewAction"
            :id="`view-row-${data.item[idKey]}-btn`"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="link"
            class="p-1 text-secondary"
            :to="{ name: (viewActionRoute || `${storeModuleName}.view`), params: { id: data.item[idKey] } }"
          >
            <feather-icon
              icon="FileTextIcon"
              size="18"
            />
            <div
              class="pt-50"
            >
              {{ viewActionLabel ? viewActionLabel : $t(`Details`) }}
            </div>
          </b-button>

          <!-- Edit Action -->
          <template v-if="editAction">
            <template v-if="editAction==='sidebar'">
              <b-button
                v-if="editAction"
                :id="`edit-row-${data.item.id}-btn`"
                v-ripple.400="'rgb(49, 49, 49, 0.15)'"
                v-b-toggle.add-new-sidebar
                variant="link"
                class="p-1 text-secondary btn-link"
                :disabled="data.item.edit_locked"
                @click="itemInFocusId=data.item.id"
              >
                <feather-icon
                  icon="EditIcon"
                  size="18"
                />
                <div
                  v-t="`Edit`"
                  class="pt-50"
                />
              </b-button>
            </template>
            <template v-else>
              <b-button
                v-if="editAction"
                :id="`edit-row-${data.item.id}-btn`"
                v-ripple.400="'rgb(49, 49, 49, 0.15)'"
                variant="link"
                class="p-1 text-secondary btn-link"
                :to="{ name: `${storeModuleName}.edit`, params: { id: data.item.id } }"
                :disabled="data.item.edit_locked"
              >
                <feather-icon
                  icon="EditIcon"
                  size="18"
                />
                <div
                  v-t="`Edit`"
                  class="pt-50"
                />
              </b-button>
            </template>
          </template>

          <!-- Delete Action -->
          <b-button
            v-if="deleteAction && !data.item.delete_locked"
            :id="`delete-row-${data.item.id}-btn`"
            v-ripple.400="'rgb(49, 49, 49, 0.15)'"
            variant="link"
            class="p-1 text-secondary"
            @click="deleteItems($event, data.item)"
          >
            <feather-icon
              :icon="deleteIcon"
              size="18"
            />
            <div
              v-t="deleteLabel"
              class="pt-50"
            />
          </b-button>

          <slot
            name="actions"
            :item="data.item"
          />

          <b-dropdown
            v-if="extraActions && extraActions.length"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: `${storeModuleName}.edit`, params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div></template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label v-t="`message.tableMeta.show`" />
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label v-t="`message.tableMeta.entries`" />
        </b-col>

        <b-col
          cols="12"
          sm="3"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t(`message.tableMeta.showing`) }} {{ dataMeta.from }} {{ $t(`message.tableMeta.to`) }} {{ dataMeta.to }} {{ $t(`message.tableMeta.of`) }} {{ dataMeta.of }} {{ $t(`message.tableMeta._entries`) }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="5"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <template v-if="sidebar">
      <component
        :is="sidebar"
        id="add-new-sidebar"
        :is-sidebar-active.sync="isAddNewSidebarActive"
        :item-id.sync="itemInFocusId"
        @refetch-data="refetchItems"
      />
    </template>

  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown, BDropdownItem, BFormCheckbox,
  BFormInput,
  BImg,
  BLink,
  BMedia, BPagination,
  BRow, BSpinner,
  BTable, BTooltip, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { draggabletable } from '@core/utils/v-draggable'
import useModelList from '@/views/models/common/useModelList'
import { avatarText, formatDatetime, priceText } from '@core/utils/filter'
import defaultProductImage from '@/assets/images/pages/eCommerce/placeholder.webp'
import { getLocaleCurrency, storagePath } from '@core/utils/utils'
import { ref, watch } from 'vue'

export default {
  name: 'ModelListTable',
  components: {
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BImg,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    BSpinner,

    vSelect,
  },
  directives: {
    Ripple,
    draggabletable,
    'b-toggle': VBToggle,
  },
  props: {
    storeModuleName: {
      type: String,
      default: String,
    },
    tableColumns: {
      type: Array,
      default: Array,
    },
    filtersList: {
      type: Array,
      default: Array,
    },
    queryParams: {
      type: Function,
      default: Function,
    },
    addAction: {
      type: [Boolean, String],
      default: false,
    },
    viewAction: {
      type: [Boolean, String],
      default: false,
    },
    viewActionLabel: {
      type: String,
      default: null,
    },
    viewActionRoute: {
      type: String,
      required: false,
      default: null,
    },
    idKey: {
      type: String,
      default: () => 'id',
    },
    previewAction: {
      type: [Boolean, String],
      default: false,
    },
    editAction: {
      type: [Boolean, String],
      default: false,
    },
    deleteAction: {
      type: [Boolean, String],
      default: false,
    },
    bulkDeleteAction: {
      type: Boolean,
      default: true,
    },
    deleteIcon: {
      type: String,
      default: 'TrashIcon',
    },
    deleteLabel: {
      type: String,
      default: 'Delete',
    },
    extraActions: {
      type: Array,
      default: Array,
    },
    sidebar: {
      type: [Object, null],
      default: null,
    },
    isSortable: {
      type: Boolean,
      default: false,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rowUnderHover: null,
    }
  },
  emit: ['update:meta', 'items-count'],
  methods: {
    priceText,
    getLocaleCurrency,
    formatDatetime,
    storagePath,
  },
  setup(props, ctx) {
    const isSortingActive = ref(false)
    const isAddNewSidebarActive = ref(false)
    const itemInFocusId = ref(null)

    const {
      fetchItems,
      deleteItems,
      saveRowsSorting,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      rowsSelected,
      refreshData,
      syncRowsSelection,
      syncRowSelection,
      onRowSelected,

      isAllRowsSelected,
      isSomeRowsSelected,
      isTableBusy,
      hasReorderedRows,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveOrderStatusVariant,
      resolvePurchaseStatusVariant,
      resolveCommissionStatusVariant,
      resolveNotificationTemplateStatusVariant,
      resolveBooleanStatusVariant,
      resolveNotificationStatusVariantAndIcon,
    } = useModelList(
      props.storeModuleName,
      props.tableColumns,
      props.filtersList,
      props.queryParams,
      () => {
        isSortingActive.value = false
      },
    )

    const resetFocusAndRefreshData = () => {
      itemInFocusId.value = null
      refreshData()
    }

    const startSorting = () => {
      sortBy.value = sortBy.value ? 'display_order' : null
      isSortDirDesc.value = true
      isSortingActive.value = true
    }

    watch(dataMeta, () => {
      ctx.emit('update:meta', dataMeta.value)
    })

    watch(totalItems, () => {
      if (!searchQuery.value) { // Avoid reporting totalItems value if search query used
        ctx.emit('items-count', totalItems.value)
      }
    })

    return {
      fetchItems,
      refetchItems: resetFocusAndRefreshData,
      deleteItems,
      saveRowsSorting,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      rowsSelected,
      refreshData,
      syncRowsSelection,
      syncRowSelection,
      onRowSelected,
      isAllRowsSelected,
      isSomeRowsSelected,
      isTableBusy,
      hasReorderedRows,
      isSortingActive,
      startSorting,

      // Methods

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveOrderStatusVariant,
      resolvePurchaseStatusVariant,
      resolveCommissionStatusVariant,
      resolveNotificationTemplateStatusVariant,
      resolveBooleanStatusVariant,
      resolveNotificationStatusVariantAndIcon,

      // Extra
      defaultProductImage,

      // Sidebar
      isAddNewSidebarActive,
      itemInFocusId,
    }
  },
}
</script>

<style scoped>

</style>
