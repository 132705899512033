export default {
  namespaced: true,
  name: 'product_groups',

  translatedAttributes: [
    'name',
    'short_description',
    'description',
  ],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray: item => ({
    id: item.id,
    slug: item.meta?.slug || null,
    product_group_id: item.product_group_id ?? null,
    name_en: item.name.en,
    name_ar: item.name.ar,
    short_description_en: item.short_description.en,
    short_description_ar: item.short_description.ar,
    description_en: item.description.en,
    description_ar: item.description.ar,
    published: true,
    currency_code: item.currency_code,
    requires_shipping: item.requires_shipping,
    free_shipping: item.has_free_shipping,
    is_tax_free: item.is_tax_free,
    is_published: item.is_published,
    weight_amount: item.weight_amount,
    weight_unit: item.weight_unit,
    categories: item.categories ? item.categories.map(category => category.id) : [],
    type: item.type,
    options: item.type !== 'basic' ? item.options.map(o => ({
      id: o.id,
      name_en: o.name.name_en,
      name_ar: o.name.name_ar,
      type: o.name.type || o.type,
      values: o.values.map(v => ({
        id: v.id,
        name_en: v.name_en,
        name_ar: v.name_ar,
        internal_value: v?.internal_value,
      })),
    })) : [],
    products: item.type !== 'basic' ? item.products.map((product, productIndex) => ({
      ...product,
      name_en: product.name.en,
      name_ar: product.name.ar,
      price: product.price * 100,
      sale_price: product.sale_price !== null ? product.sale_price * 100 : null,
      cost_price: product.cost_price !== null ? product.cost_price * 100 : null,
      ...(productIndex === 0 ? { images: item.images } : {}),
    })) : [
      {
        price: item.price * 100,
        sale_price: item.sale_price !== null ? item.sale_price * 100 : null,
        cost_price: item.cost_price !== null ? item.cost_price * 100 : null,
        images: item.images,
        sku: item.sku,
        qty: item.qty,
        has_unlimited_qty: item.has_unlimited_qty,
        limit_per_order: null,
        min_per_order: null,
      },
    ],
    meta: item.meta,
  }),

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => {
    let category = null
    const categoriesKeys = Object.keys(data.categories || {})
    if (categoriesKeys.length) {
      const catId = categoriesKeys[0]
      category = {
        id: catId,
        label: data.categories[catId],
      }
    }
    return {
      ...initialItemData,
      id: data.id,
      uuid: data.id,
      type: data.type,
      slug: data.slug,
      name: {
        en: data.name_en,
        ar: data.name_ar,
      },
      short_description: {
        en: data.short_description_en,
        ar: data.short_description_ar,
      },
      description: {
        en: data.description_en,
        ar: data.description_ar,
      },
      currency_code: data.currency_code || data.price.currency || null,
      // price_text: data.price_text,
      // sale_price_text: data.sale_price_text,
      requires_shipping: !!data.requires_shipping,
      is_tax_free: !!data.is_tax_free,
      is_published: !!data.is_active,
      weight_amount: data.weight_amount,
      weight_unit: data.weight_unit,

      category,
      categories: data.categories_data,

      price: data?.products?.[0]?.price?.amount / 100,
      sale_price: data?.products?.[0]?.sale_price?.amount !== null ? data?.products?.[0]?.sale_price?.amount / 100 : null,
      cost_price: data?.products?.[0]?.cost_price?.amount !== null ? data?.products?.[0]?.cost_price?.amount / 100 : null,
      sku: data?.products?.[0]?.sku,
      qty: data?.products?.[0]?.qty,
      has_unlimited_qty: !!data?.products?.[0]?.has_unlimited_qty,
      limit_per_order: data?.products?.[0]?.limit_per_order,
      min_per_order: data?.products?.[0]?.min_per_order,
      images_files: data?.products?.[0]?.images,

      options: data.options.map(o => ({ ...o, original_values: o.values })),

      products: data.products.map(product => ({
        ...product,
        name: {
          en: product.name_en,
          ar: product.name_ar,
        },
        price: product.price?.amount / 100,
        sale_price: product.sale_price?.amount !== null ? product.sale_price?.amount / 100 : null,
        cost_price: product.cost_price?.amount !== null ? product.cost_price?.amount / 100 : null,
        sku: product.sku,
        qty: product.qty,
        has_unlimited_qty: product?.has_unlimited_qty,
        limit_per_order: product.limit_per_order,
        min_per_order: product.min_per_order,
        images_files: product.images,
        options: product.options,
        options_values: product.options_values,
      })),

      meta: {
        title: {
          en: data?.meta?.title?.en || null,
          ar: data?.meta?.title?.ar || null,
        },
        description: {
          en: data?.meta?.description?.en || null,
          ar: data?.meta?.description?.ar || null,
        },
        slug: data.slug,
        // ...(data?.meta || {}),
      },
    }
  },

  resolveValidationErrors: errors => ({
    id: errors.id,
    name: {
      en: errors.name_en,
      ar: errors.name_ar,
    },
    category: errors.categories || errors?.['categories.0'],
    short_description: {
      en: errors.short_description_en,
      ar: errors.short_description_ar,
    },
    description: {
      en: errors.description_en,
      ar: errors.description_ar,
    },

    weight_amount: errors.weight_amount,
    weight_unit: errors.weight_unit,

    requires_shipping: errors.requires_shipping,
    has_free_shipping: errors.free_shipping,
    is_tax_free: errors.is_tax_free,
    is_published: errors.is_published,

    currency_code: errors.currency_code,
    price: errors?.['products.0.price'],
    sale_price: errors?.['products.0.sale_price'],
    cost_price: errors?.['products.0.cost_price'],

    sku: errors?.['products.0.sku'],
    qty: errors?.['products.0.qty'],
    has_unlimited_qty: errors?.['products.0.has_unlimited_qty'],
    limit_per_order: errors?.['products.0.limit_per_order'],
    min_per_order: errors?.['products.0.min_per_order'],

    images: errors?.['products.0.images'],
    meta: {
      slug: errors?.slug,
      title: errors?.title,
      description: errors?.description,
      keywords: errors?.keywords,
    },
  }),
}
